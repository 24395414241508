<template>

    <b-card style="margin-bottom:10px">
    
    <b-row>
    
      
      <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px">
    
      <p style="font-weight: bold; text-align: center; margin-bottom:10px; font-size:14px;">Comisiones para promotores por ventas</p>
    
    
      </b-col>
    
    
    
    
        <b-col v-if="hasPorcentajes"  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px">

            <b-row>

                 
                <b-col v-if="porcentajeUno != '0' || porcentajeUno != 0"  cols="4" sm="4" md="4" lg="4" xl="4" style="margin-bottom:10px;" >

                  <b-badge style="font-size: 16px;" block variant="success">
                      <span style="font-size:12px">$</span>{{ comision1 }} <span style="font-size:12px">USD</span>
                        </b-badge> 
                    
                </b-col>

                <b-col v-if="porcentajeUno != '0' || porcentajeUno != 0" cols="8" sm="8" md="8" lg="8" xl="8" >
                    <p style="font-size:12px;margin:0px;  text-align: left;">Para promotor de 1er nivel</p>
                    
                </b-col>

                <b-col v-if="porcentajeDos != '0' || porcentajeDos != 0"  cols="4" sm="4" md="4" lg="4" xl="4" style=" margin-bottom:10px;" >

                  <b-badge style="font-size: 16px;" block variant="warning">
                      <span style="font-size:12px">$</span>{{ comision2 }} <span style="font-size:12px">USD</span>
                        </b-badge> 

                        </b-col>

                        <b-col v-if="porcentajeDos != '0' || porcentajeDos != 0" cols="8" sm="8" md="8" lg="8" xl="8" >
                            <p style="font-size:12px;margin:0px; text-align: left;">Para promotor de 2do nivel</p>
                    
                        </b-col>


                        <b-col v-if="porcentajeTres != '0' || porcentajeTres != 0"  cols="4" sm="4" md="4" lg="4" xl="4" >

                          <b-badge style="font-size: 16px;" block variant="danger">
                      <span style="font-size:12px">$</span>{{ comision3 }} <span style="font-size:12px">USD</span>
                        </b-badge> 

                        </b-col>

                        <b-col v-if="porcentajeTres != '0' || porcentajeTres != 0" cols="8" sm="8" md="8" lg="8" xl="8">
                            <p style="font-size:12px; margin:0px; text-align: left;">Para promotor de 3er nivel</p>
                    
                        </b-col>

            </b-row>
    
      
    
    
          </b-col>

          <b-col v-else  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px">

            <b-alert
                variant="secondary"
                show
                style="margin-top:0px;"
                >
                <div class="alert-body">
                        

                    <p style="text-align: left;font-size:13px;margin:0px">Este negocio no dispone de comisiones de venta para promotores</p>
                   

                </div>
                </b-alert>
    
      
    
    
            </b-col>
    
          
    
    </b-row>
    
    </b-card>
             
          
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard,BAlert, BBadge
          } from 'bootstrap-vue'
          
          
          
          export default {
            components: {
                BBadge,
                BAlert,
              BButton,
              BRow,
              BCol,
              BCard,
      
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: ['price','porcentajeUno','porcentajeDos','porcentajeTres'],
            data() {

              let porcentaje1=Number(Number(this.porcentajeUno) / Number("100")).toFixed(2)

              let comision1= Number(Number(this.price)*Number(porcentaje1)).toFixed(2);

              let porcentaje2=Number(Number(this.porcentajeDos) / Number("100")).toFixed(2)

            let comision2= Number(Number(this.price)*Number(porcentaje2)).toFixed(2);



          let porcentaje3=Number(Number(this.porcentajeTres) / Number("100")).toFixed(2)

          let comision3= Number(Number(this.price)*Number(porcentaje3)).toFixed(2);

          
          
              return {

                hasPorcentajes:false,
                comision1:comision1,
                comision2:comision2,
                comision3:comision3
          
          
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            created(){

                if(Number(this.porcentajeUno) === Number("0") && Number(this.porcentajeDos) === Number("0") && Number(this.porcentajeTres) === Number("0")){

                    this.hasPorcentajes=false;

                }else{
                    this.hasPorcentajes=true;
                }

            },
            mounted() {
          
            },
            methods: {
      
              
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          