<template>

    <b-card style="margin-bottom:10px">

        <b-row v-if="loading">
 


  <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

    <b-skeleton  width="40px" type="avatar" height="40px" style="margin-bottom:5px"></b-skeleton>


        </b-col>

        <b-col cols="9" sm="9" md="9" lg="9" xl="9" align="center">

          <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
          <b-skeleton  width="60%"  height="10px" style="margin-bottom:0px"></b-skeleton>




          

        </b-col>

        <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

          <b-skeleton  width="40px" type="avatar" height="40px" style="margin-bottom:5px"></b-skeleton>


              </b-col>

              <b-col cols="9" sm="9" md="9" lg="9" xl="9" align="center">

                <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                <b-skeleton  width="60%"  height="10px" style="margin-bottom:0px"></b-skeleton>




                

              </b-col>
              <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

          <b-skeleton  width="40px" type="avatar" height="40px" style="margin-bottom:5px"></b-skeleton>


              </b-col>

              <b-col cols="9" sm="9" md="9" lg="9" xl="9" align="center">

                <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                <b-skeleton  width="60%"  height="10px" style="margin-bottom:0px"></b-skeleton>




                

              </b-col>
           


        </b-row>

        <b-row v-else>

       

            <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

                         
            <b-avatar
                    size="50"
                    variant="light-primary"
                    >

                    <b-img-lazy  v-bind="mainProps" style="border-radius: 50px;"  :src="foto" :alt="alias"></b-img-lazy>

                    </b-avatar>

            </b-col>

            <b-col cols="9" sm="9" md="9" lg="9" xl="9" style="margin-bottom:10px">

                <h4  style="text-transform: capitalize; display: -webkit-box;vertical-align: middle;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 2;-webkit-box-orient: vertical;font-weight: bold;font-size:16px; text-align:left !important;   margin-top: 8px;margin-bottom: 0px;" class=" h4_title">
                          {{ name }}
                          </h4>

                          <h6
                          
                         
                          style="text-align: left; color: #adadad !important; font-size:12px"
                          >
                          @{{ alias }}         <img v-if="isProfileVerificated" title="Usuario verificado" alt="Usuario verificado" class="badgeVerificacion2" src="/img/verificadoFinal.svg" />
                    <img v-else alt="Usuario no verificado" title="Usuario no verificado" class="badgeVerificacion2" src="/img/no_verificadoFinal.svg" />

                          </h6>


              

            </b-col>





                  <b-col  cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                    <b-avatar
                        size="30"
                        variant="light-primary"
                        style="margin:auto"
                        >

                    <feather-icon
                      icon="StarIcon"
                      size="16"
                    
                      />

                      </b-avatar>

                    </b-col>

                    <b-col  cols="10" sm="10" md="10" lg="10" xl="10" style="margin-bottom:10px; ">

                        <p style="font-weight: bold; text-align: left; margin:0px; font-size:12px;">Calificación del negocio:</p>
                      <b-form-rating style="    width: 70%;" show-value show-value-max precision="1" variant="warning"  size="sm" no-border v-model="rating" readonly></b-form-rating>



              

                      </b-col>


                      <b-col  cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                        <b-avatar
                            size="30"
                            variant="light-primary"
                            style="margin:auto"
                            >

                        <feather-icon
                          icon="ShoppingBagIcon"
                          size="16"

                          />

                          </b-avatar>

                        </b-col>

                        <b-col  cols="10" sm="10" md="10" lg="10" xl="10" style="margin-bottom:0px">

                          <p style="font-weight: bold; text-align: left; margin:0px; font-size:12px;">Historial de ventas:</p>
                          <p style="text-align: left; margin:0px; font-size:14px;">{{ nro_ventas }} ventas realizadas</p>




                          </b-col>

                       


 

                 

                </b-row>

                <div  class="float2_div_shop" v-show="show"       :class="{'showIndex': index}"  >

<div class="float_div_shop">
  <b-row>
    <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="display:flex;padding: 0px;">
      <div style="margin:auto;">
        <p class="color_price" style="margin:0px; text-align: left; font-size:12px;font-weight: bold;">Precio</p>
      <p class="p_suscripciones2 color_price"  style="margin:0px; font-size:20px !important"><span style="    font-size: 14px;">$</span>{{ price }} <span style="    font-size: 10px;">USD</span></p>
      </div>
    
    </b-col>

    <b-col cols="6" sm="6" md="6" lg="6" xl="6" style="display:flex">

      <b-button
      class="animacion_button"
      variant="primary"
      size="sm"
      block
      @click="openModal()"
    >



      <span v-if="recurrencia === 'Pago unico'">¡ Comprar Ahora!</span>
      <span v-else>¡ Suscribirme Ahora!</span>

    </b-button>

    </b-col>

    <b-col cols="2" sm="2" md="2" lg="2" xl="2" >

      <b-skeleton v-if="loading" width="100%"  height="50px" style="margin-bottom:0px"></b-skeleton>
      <a  v-else-if="phoneWhatsapp != ''"  v-b-tooltip.hover.top="'¿Necesita más información acerca de esta suscripción? Contáctate con el negocio'" :href="linkText" target="_blank"  >
      <b-button
     style="    padding: 13px 0px;"
      variant="outline-secondary"
      size="md"
      block

    >
    <svg width="25" height="25"  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentcolor"><path d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>



    </b-button>
    </a>

    </b-col>
  </b-row>

            </div>


</div>

<modal-pay-pets v-if="showModalPayPets" :promo-code="promoCode" :key="componentKeyModalPayPets" :display-name="displayName" :photo-user="photoUser" :title="title" :recurrencia="recurrencia" :user-id="userId" :token-auth="tokenAuth" :foto="foto" :alias="alias" :id-producto="idProducto" :price="price" />
<modal-pay-seguidores v-if="showModalSeguidores" :chamaya="chamaya" :promo-code="promoCode" :key="componentKeyModalSeguidores" :display-name="displayName" :photo-user="photoUser" :title="title" :recurrencia="recurrencia" :user-id="userId" :token-auth="tokenAuth" :foto="foto" :alias="alias" :id-producto="idProducto" :price="price" />

<modal-pay-generico v-if="showModalPayGenerico" :promo-code="promoCode" :key="componentKeyModalPayGenerico" :display-name="displayName" :photo-user="photoUser" :title="title" :recurrencia="recurrencia" :user-id="userId" :token-auth="tokenAuth" :foto="foto" :alias="alias" :id-producto="idProducto" :price="price" />

    </b-card>
 
    
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BAvatar,BSkeleton, BImgLazy,VBTooltip, BFormRating
      } from 'bootstrap-vue'
      
      import ModalPayGenerico from './ModalPayGenerico.vue'
      import ModalPayPets from './ModalPayPets.vue'
    
      import ModalPaySeguidores from './ModalPaySeguidores.vue'
    
      export default {
        components: {
            BImgLazy,
            BFormRating,
            BAvatar,BSkeleton,
          BButton,
          BRow,
          BCol,
          BCard,
          ModalPayGenerico,
          ModalPaySeguidores,
          ModalPayPets
        },
        directives: {
          'b-popover': VBPopover,
          'b-tooltip': VBTooltip,
        },
        props: ['alias','chamaya','idProducto','price','displayName','promoCode','photoUser','title','recurrencia', 'tokenAuth', 'userId','index', 'isLogeo'],
        data() {
      


          return {
            loading:true,
            name:"",
            phoneWhatsapp:"",
            scrollPosition:90,
            show:true,
            linkText:"",
            rating:5,
            nro_ventas:0,
            isProfileVerificated:true,
            mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: 30,
                      height: 30,

                    },
                    showModalPayGenerico:false,
                    showModalPayPets:false,
                    showModalSeguidores:false,
                    componentKeyModalPayGenerico:0,
                    componentKeyModalPayPets:100,
                    componentKeyModalSeguidores:200,
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        created() {

        
    window.addEventListener("scroll", this.handleScroll);

  },
        destroyed() {
    window.removeEventListener("scroll",this.handleScroll);  
  },

        mounted() {

       
          this.loadFoto();

          

                 
          this.$eventBus.$on('reiniciarModalSeguidores', () => {
            this.componentKeyModalSeguidores += 1
            this.showModalSeguidores=false;
              });
          
          this.$eventBus.$on('reiniciarModalPayGenerico', () => {
            this.componentKeyModalPayGenerico += 1
            this.showModalPayGenerico=false;
              });
          
          
              this.$eventBus.$on('reiniciarModalPayPets', () => {
            this.componentKeyModalPayPets += 1
            this.showModalPayPets=false;
              });

              
           
      
        },
        methods: {

          openModal(){

            if(this.isLogeo){


              if(this.chamaya==="No"){

                this.showModalPayGenerico=true;
                this.showModalSeguidores=false;


              }else{
                this.showModalSeguidores=true;
                this.showModalPayGenerico=false;
              }


       

             

            }else{

    

                        this.$eventBus.$emit('showModalLogin');

            }

          

          },

          handleScroll() {


            var currentScrollPosition = window.scrollY

            if(Number(currentScrollPosition) < Number(this.scrollPosition)){

              this.show = true

            }
            else{
                this.show = false


            }

            this.scrollPosition = window.scrollY

            }, 
  
            loadFoto(){

                this.loading=true;

                this.$https.post('/locals/getPhoto/', { id: this.idProducto}).then(response => {


                 
                    if (response.data.code == 200) {

                        this.loading = false;
              

                        this.foto= response.data.photo;
                        this.name= response.data.name;
                        this.phoneWhatsapp= response.data.phone;
                        this.rating=response.data.rating;
                        this.nro_ventas=response.data.nro_ventas;

                        this.linkText="https://api.whatsapp.com/send?phone="+this.phoneWhatsapp+"&text=Hola,%20necesito%20más%20información%20acerca%20de%20"+this.title;
                    

                    } else {


                    if (response.data.code == 401) {

                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
                        
                        localStorage.removeItem('userData')


                        this.$router.push({ name: 'auth-login' })
                    } else {

                        if(response.data.code == 404){

                        //no existe

                    

                    
                        this.loading= false;
                        this.empty=true;
                        this.isDelete=false;
                        this.isNotSuscription=false;
                    

                    
                        }else{

                        
                        if(response.data.code == 405){

                        //el usuario eliminó la publicación
                        this.loading= false;
                        this.empty=false;
                        this.isDelete=true;
                        this.isNotSuscription=false;

                        }else{
                        
                        if(response.data.code == 406){

                        //el usuario no tiene activado una suscripción

                        this.loading= false;
                        this.empty=false;
                        this.isDelete=false;
                        this.isNotSuscription=true;

                            }else{
                            this.loadFoto();
                            }
                        }


                        
                        

                        
                        }
                        
                    }
                    }
                    }).catch(error => {
                    this.loadFoto();
                    })
  
  
           
          }
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      