<template>

  <div>

    <b-row v-if="loading">

      <b-col cols="12" sm="12" md="12" lg="5" xl="5" style="padding:0px">
          <b-row>

            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="padding-right: 0px;">
          
              <b-skeleton  width="100%"  height="60px" style="margin-bottom:5px"></b-skeleton>
                <b-skeleton  width="100%"  height="60px" style="margin-bottom:5px"></b-skeleton>
                <b-skeleton  width="100%"  height="60px" style="margin-bottom:5px"></b-skeleton>
                <b-skeleton  width="100%"  height="60px" style="margin-bottom:5px"></b-skeleton>

            

        </b-col>
        <b-col cols="10" sm="10" md="10" lg="10" xl="10" style="margin-bottom:10px">

          <b-skeleton  width="100%"  height="100%" style="margin: 0px;"></b-skeleton>



        </b-col>

        <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="!isMobile" style="margin-bottom: 15px;">
      
          <p style="font-weight: bold;font-size:15px; text-align: left;margin-bottom:15px">Suscripciones destacadas</p>

             <b-row>

            <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

              <b-skeleton width="100%"  height="150px"></b-skeleton>
                <b-skeleton width="30%"  height="10px"></b-skeleton>
                <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                  </b-col>

                                  <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

                                    <b-skeleton width="100%"  height="150px"></b-skeleton>
                <b-skeleton width="30%"  height="10px"></b-skeleton>
                <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                    </b-col>

                                    <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

                                      <b-skeleton width="100%"  height="150px"></b-skeleton>
                <b-skeleton width="30%"  height="10px"></b-skeleton>
                <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                    </b-col>


                                    </b-row>
     
       

        </b-col>


          </b-row>

        </b-col>

        <b-col cols="12" sm="12" md="12" lg="7" xl="7" style="padding:0px">
          <b-row>

            <b-col cols="12" sm="12" md="6" lg="12" xl="7">

              <b-card>



              <b-row>

                <b-col cols="10" sm="10" md="10" lg="10" xl="10" class=" biografiatext3">

                  <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                  <b-skeleton  width="30%"  height="10px" style="margin-bottom:5px"></b-skeleton>


                  </b-col>

                  <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="text-align: right;">

                    <b-skeleton  width="40px"  height="40px" style="margin-bottom:10px" type="avatar"></b-skeleton>


                  </b-col>

                  <b-col  cols="6" sm="9" md="8" lg="8" xl="8" style="text-align: left;margin-top:10px" >
                    <b-skeleton  width="50%"  height="30px" style="margin-bottom:5px"></b-skeleton>
                     </b-col>


                  <b-col  cols="6" sm="3" md="4" lg="4" xl="4" style=" text-align: right;display:flex;">
                   
                    <b-skeleton  width="100%"  height="30px" style="margin-bottom:5px"></b-skeleton>

                   </b-col>
                


                          



              </b-row>

              </b-card>

              <b-card>

                <b-row>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                    <p style="text-align: left;font-weight: bold; font-size:16px; margin-bottom:0px; margin-top:0px">Detalle de la suscripción:</p>
                      <hr>
                    </b-col>

                    
              <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

<b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
  <b-skeleton  width="80%"  height="10px" style="margin-bottom:10px"></b-skeleton>
  <b-skeleton  width="60%"  height="10px" style="margin-bottom:10px"></b-skeleton>
  <b-skeleton  width="90%"  height="10px" style="margin-bottom:10px"></b-skeleton>
  <b-skeleton  width="40%"  height="10px" style="margin-bottom:10px"></b-skeleton>
  <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>

</b-col>


                </b-row>


            


              </b-card>

              </b-col>

              <b-col cols="12" sm="12" md="6" lg="12" xl="5" style="    padding: 0px;">
           
                <b-row>

                  <b-col cols="12" sm="12" md="12" lg="12" xl="12" >
                    <b-card>

                      <b-skeleton  width="100%" height="10px" style="margin-bottom:5px"></b-skeleton>
                      <b-skeleton  width="50%" height="10px" style="margin-bottom:15px"></b-skeleton>

                        <b-skeleton  width="100%" height="40px" style="margin-bottom:5px"></b-skeleton>
                    </b-card>

                  </b-col>

                  

                  <b-col cols="12" sm="12" md="12" lg="6" xl="12" >
                    <b-card>

           
                      <b-row>
    
      
                          <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px">
                        
                          <p style="font-weight: bold; text-align: center; margin-bottom:10px; font-size:14px;">Comisiones para promotores por ventas</p>
                        
                        
                          </b-col>

                          <b-col  cols="3" sm="3" md="3" lg="3" xl="3">
                            <b-skeleton  width="100%" height="30px" style="margin-bottom:5px"></b-skeleton>


                          </b-col>

                          <b-col  cols="9" sm="9" md="9" lg="9" xl="9">

                            <b-skeleton  width="100%" height="10px" style="margin-bottom:5px"></b-skeleton>

                        </b-col>

                        <b-col  cols="3" sm="3" md="3" lg="3" xl="3">
                            <b-skeleton  width="100%" height="30px" style="margin-bottom:5px"></b-skeleton>


                          </b-col>

                          <b-col  cols="9" sm="9" md="9" lg="9" xl="9">

                            <b-skeleton  width="100%" height="10px" style="margin-bottom:5px"></b-skeleton>

                        </b-col>


                        <b-col  cols="3" sm="3" md="3" lg="3" xl="3">
                            <b-skeleton  width="100%" height="30px" style="margin-bottom:5px"></b-skeleton>


                          </b-col>

                          <b-col  cols="9" sm="9" md="9" lg="9" xl="9">

                            <b-skeleton  width="100%" height="10px" style="margin-bottom:5px"></b-skeleton>

                        </b-col>



                          </b-row>
                  </b-card>

                    </b-col>

              <b-col cols="12" sm="12" md="12" lg="6" xl="12" >

                <b-card>

                  <b-row>

                    



                    <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

                      <b-skeleton  width="40px" type="avatar" height="40px" style="margin-bottom:5px"></b-skeleton>


                          </b-col>

                          <b-col cols="9" sm="9" md="9" lg="9" xl="9" align="center">

                            <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                            <b-skeleton  width="60%"  height="10px" style="margin-bottom:0px"></b-skeleton>




                            

                          </b-col>

                          <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

                            <b-skeleton  width="40px" type="avatar" height="40px" style="margin-bottom:5px"></b-skeleton>


                                </b-col>

                                <b-col cols="9" sm="9" md="9" lg="9" xl="9" align="center">

                                  <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                                  <b-skeleton  width="60%"  height="10px" style="margin-bottom:0px"></b-skeleton>




                                  

                                </b-col>
                                <b-col cols="3" sm="3" md="3" lg="3" xl="3" style="padding:0px" align="center">

                            <b-skeleton  width="40px" type="avatar" height="40px" style="margin-bottom:5px"></b-skeleton>


                                </b-col>

                                <b-col cols="9" sm="9" md="9" lg="9" xl="9" align="center">

                                  <b-skeleton  width="100%"  height="10px" style="margin-bottom:10px"></b-skeleton>
                                  <b-skeleton  width="60%"  height="10px" style="margin-bottom:0px"></b-skeleton>




                                  

                                </b-col>
                           





                  </b-row>



                  </b-card>


                </b-col>

               

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" >


                  <card-advertencia />

                </b-col>


              </b-row>

             
             




              </b-col>

              <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="isMobile" style="margin-bottom: 15px;">
               
                    <p style="font-weight: bold;font-size:15px; text-align: left;margin-bottom:15px">Suscripciones destacadas</p>
                    <b-row>

<b-col cols="4" sm="4" md="4" lg="4" xl="4" >

  <b-skeleton width="100%"  height="150px"></b-skeleton>
    <b-skeleton width="30%"  height="10px"></b-skeleton>
    <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                      </b-col>

                      <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

                        <b-skeleton width="100%"  height="150px"></b-skeleton>
                        <b-skeleton width="30%"  height="10px"></b-skeleton>
                        <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                            </b-col>

                                            <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

                                              <b-skeleton width="100%"  height="150px"></b-skeleton>
                        <b-skeleton width="30%"  height="10px"></b-skeleton>
                        <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                            </b-col>


                                            </b-row>
                  

                  </b-col>

          </b-row>

        </b-col>
     

    </b-row>

    <div class="misc-wrapper" v-else-if="empty">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <img src="/img/404_nuevo.svg" style="width: 350px;" />
        <h2 class="mb-1" style="font-weight: bold;margin-top:25px">
          Publicación no encontrada 🕵🏻‍♀️
        </h2>
        <p class="mb-2" style="font-size:16px; ">
          Oops! 😖 La publicación que buscabas no existe.
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{name:'suscripciones'}"
        >
          Ver otras publicaciones
        </b-button>

      </div>
    </div>
  </div>


    <div class="misc-wrapper" v-else-if="isNotSuscription">

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <img src="/img/cart.svg" style="width: 200px;" />
          <h2 class="mb-1" style="font-weight: bold;margin-top:25px">
            Publicación no disponible
          </h2>
          <p class="mb-2" style="font-size:16px; ">
            Oops! 😖 Esta publicación no se encuentra activa por el momento.
          </p>

          <b-button
            variant="primary"
            class="mb-2 btn-sm-block"
            :to="{name:'suscripciones'}"
          >
            Ver otras publicaciones
          </b-button>

        </div>
      </div>
      </div>

    <div class="misc-wrapper" v-else-if="isDelete">

        <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <img src="/img/cart.svg" style="width: 200px;" />
            <h2 class="mb-1" style="font-weight: bold;margin-top:25px">
              Publicación no encontrada 🕵🏻‍♀️
            </h2>
            <p class="mb-2" style="font-size:16px; ">
              Oops! 😖 La publicación que buscabas fue eliminada por su autor.
            </p>

            <b-button
              variant="primary"
              class="mb-2 btn-sm-block"
              :to="{name:'suscripciones'}"
            >
              Ver otras publicaciones
            </b-button>

          </div>
        </div>
        </div>

        <b-row v-else style="margin-bottom: 65px;">

          <b-col cols="12" sm="12" md="12" lg="5" xl="5" style="padding:0px">
            <b-row>

              <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="padding-right: 0px;">
            
            <div @click="changeImagen(urlImagen1, 'imagen1')"     @mouseenter="changeImagen(urlImagen1, 'imagen1')" class="thumb_img1" :class="{'thumb_img': isSelected1}">
              <b-img-lazy  style="border-radius: 0px;" v-bind="mainProps" :src="urlImagen1" :alt="title"></b-img-lazy>
 
            </div>

            <div @click="changeImagen(urlImagen2, 'imagen2')"  @mouseenter="changeImagen(urlImagen2, 'imagen2')" class="thumb_img1" :class="{'thumb_img': isSelected2}">
              <b-img-lazy  v-if="urlImagen2 != ''" style="border-radius: 0px;" v-bind="mainProps" :src="urlImagen2" :alt="title"></b-img-lazy>
           
            </div>

            <div @click="changeImagen(urlImagen3, 'imagen3')"  @mouseenter="changeImagen(urlImagen3, 'imagen3')" class="thumb_img1" :class="{'thumb_img': isSelected3}">
              <b-img-lazy v-if="urlImagen3 != ''" style="border-radius: 0px;" v-bind="mainProps" :src="urlImagen3" :alt="title"></b-img-lazy>
        
            </div>

            <div @click="changeImagen(urlImagen4, 'imagen4')"  @mouseenter="changeImagen(urlImagen4, 'imagen4')" class="thumb_img1" :class="{'thumb_img': isSelected4}">
              <b-img-lazy v-if="urlImagen4 != ''" style="border-radius: 0px;" v-bind="mainProps" :src="urlImagen4" :alt="title"></b-img-lazy>
 
            </div>

      
          
        
 
          </b-col>
          <b-col cols="10" sm="10" md="10" lg="10" xl="10" style="margin-bottom:10px">

            <inner-image-zoom :src="urlImagenShow" :zoomSrc="urlImagenShow" :fullscreenOnMobile="true" :hideCloseButton="false" :zoomPreload="true" zoomType="hover" />
            <p class=" d-none d-sm-none d-md-none d-lg-block d-xl-block" style="margin:0px; font-size:11px; text-align: center;">Pasa el mouse encima de la imagen para aplicar zoom</p>

           

       
          </b-col>

          <b-col class=" d-none d-sm-none d-md-block d-lg-block d-xl-block" cols="12" sm="12" md="12" lg="12" xl="12" v-if="!isMobile" style="margin-bottom: 15px;">
          <carousel-other v-if="showCarousel" :id-categoria="idCategoria" />

          </b-col>


            </b-row>

          </b-col>

          <b-col cols="12" sm="12" md="12" lg="7" xl="7" style="padding:0px">
            <b-row>

              <b-col cols="12" sm="12" md="6" lg="12" xl="7">

                <b-card>



                <b-row>

                  <b-col cols="10" sm="10" md="10" lg="10" xl="10" class=" biografiatext3">

                    <read-more more-str="Leer más" :text="title" link="#" less-str="Leer menos" :max-chars="100"></read-more>

                    <p style="text-align: left; font-size:12px;margin:0px"><strong>Categoria:</strong> {{ category }}</p>


                    </b-col>

                    <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="text-align: right;">

                      <b-link style="cursor:pointer" @click="shared" >

                        <b-avatar
                        size="35"
                        variant="light-primary"



                        >

                        <span class="d-flex align-items-center">


                            <feather-icon

                        icon="Share2Icon"
                        size="18"
                        class="color_icon"

                        />

                        </span>

                        </b-avatar>

                        </b-link>


                    </b-col>

                    <b-col  cols="6" sm="9" md="8" lg="8" xl="8" style="text-align: left;margin-top:10px" >
                    <b-badge variant="success">
                    
                      <p class="p_suscripciones2" ><span style="    font-size: 16px;">$</span>{{ price }} <span style="    font-size: 10px;">USD</span></p>

                                          </b-badge>

                                  
                                    </b-col>


                    <b-col  cols="6" sm="3" md="4" lg="4" xl="4" style=" text-align: right;display:flex;">
                      <div style="margin: auto;">

                        <p style="text-align: left; font-size:12px;margin-top:0px; margin-bottom:0px"><strong>Calificación: </strong></p>
                                        <b-form-rating show-value show-value-max precision="1" variant="warning"  size="sm" no-border v-model="rating" readonly></b-form-rating>


                      </div>

                                    </b-col>
                  


                            


                <b-col  cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top:10px">

                  <p style="text-align: left; font-size:12px;margin-top:10px; margin-bottom:0px"><strong>Recurrencia de pagos: </strong>
                    <b-badge variant="warning">
                      {{ recurrencia }}
                                          </b-badge>
                                          </p>

                                         

                                        </b-col>

                  <b-col  cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top:10px">

                    <p style="text-align: left; font-size:12px;margin-top:10px; margin-bottom:0px"><strong>Cobertura: </strong>
                    <b-badge variant="warning">
                      {{ cobertura }}
                                          </b-badge>
                                          </p>


                    </b-col>

                   


                </b-row>

                </b-card>

                <b-card>
                  <b-row>
                    
                   <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px">

                
                      <p style="text-align: left;font-weight: bold; font-size:16px; margin-bottom:0px; margin-top:0px">Detalle de la suscripción:</p>
                      <hr>

                      <read-more more-str="Leer más" :text="description" link="#" less-str="Leer menos" :max-chars="730"></read-more>



                      </b-col>
                  </b-row>
                </b-card>

                </b-col>

                <b-col cols="12" sm="12" md="6" lg="12" xl="5" style="    padding: 0px;">

                  <b-row>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" >

                  <b-card style="margin-bottom:10px">

                    <p style="font-weight: bold; text-align: center; margin-bottom:10px; font-size:14px;">Comparte este link y recibe    <b-badge style="font-size: 16px;" block variant="success">
                      <span style="font-size:12px">$</span>{{ comisionDirecta }} <span style="font-size:12px">USD</span>
                        </b-badge>  por cada vez que realicen una compra con tu link. </p>
    
                    <b-input-group>
                      
                      <b-form-input
                        v-model="url"
                        readonly
                      />
                      <b-input-group-append>
                        <b-button
                        @click="onCopyLink"
                          variant="outline-primary"
                        >

                          <feather-icon
                    icon="CopyIcon"
                    size="15"
                    
                    /> 
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
    


                    </b-card>

                  </b-col>


                    <b-col cols="12" sm="12" md="12" lg="6" xl="12" >


                        <card-porcentajes v-if="showPorcentajes" :price="price" :porcentaje-uno="porcentajeUno" :porcentaje-dos="porcentajeDos" :porcentaje-tres="porcentajeTres" />

                        </b-col>

                    <b-col cols="12" sm="12" md="12" lg="6" xl="12" >

                     

                      <foto-negocio v-if="showFoto" :chamaya="chamaya" :promo-code="null" :isLogeo="true" :index="false" :display-name="displayName" :photo-user="photoUser"  :user-id="userId" :token-auth="tokenAuth" :price="price" :alias="alias" :id-producto="idProducto" :title="title" :recurrencia="recurrencia" />

                     

                      </b-col>

                       <b-col cols="12" sm="12" md="12" lg="12" xl="12" >


                        <card-advertencia />

                      </b-col>


                  </b-row>




                </b-col>

                <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="isMobile">
                  <carousel-other v-if="showCarousel" :id-categoria="idCategoria" />

                    </b-col>

            </b-row>

          </b-col>


      </b-row>
    
  </div>

  
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BImgLazy, BFormRating, BSkeleton, BBadge, BLink, BAvatar, BFormInput, BInputGroup, BInputGroupAppend
      } from 'bootstrap-vue'
      import InnerImageZoom from 'vue-inner-image-zoom'
      import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
      import FotoNegocio from './FotoNegocio.vue'
      import CarouselOther from './CarouselOther.vue'
      import CardAdvertencia from './CardAdvertencia.vue'
      import CardPorcentajes from './CardPorcentajes.vue'
      export default {
        components: {
          FotoNegocio,
          CarouselOther,
          CardAdvertencia,
          CardPorcentajes,
          BAvatar,
          BLink,
          BFormInput,
          BInputGroup, BInputGroupAppend,
          BBadge,
          InnerImageZoom,
          BSkeleton,
          BFormRating,
          BImgLazy,
          BButton,
          BRow,
          BCol,
          BCard,

  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: [''],
        data() {
      
         
            const datos_json = JSON.parse(this.$userGlobal);

          return {
            chamaya:"",
            idProducto:this.$route.params.id,
            userId: datos_json.userId,
             tokenAuth: this.$tokenGlobal,
             isMobile:this.$isMobile,
             photoUser: datos_json.photoUser,
             displayName: datos_json.displayName,
             promoCode:datos_json.promoCode,
             recurrencia:"",
             loading:true,
             showCarousel:false,
             showFoto:false,
             showPorcentajes:false,
             empty:false,
             isNotSuscription:false,
             isDelete:false,
             urlImagen1:"",
             urlImagen2:"",
             urlImagen3:"",
             urlImagen4:"",
             urlImagenShow:"",
             comisionDirecta:0,
             price:"",
             rating:"",
             url: window.location.protocol+"//"+window.location.hostname+"/m/" + this.$route.params.id+"/"+datos_json.promoCode,
             category:"",
             title:"",
             description:"",
             cobertura:"",
             porcentajeUno:"",
             porcentajeDos:"",
             porcentajeTres:"",
             alias:"",
             idCategoria:"",
             isSelected1:true,
             isSelected2:false,
             isSelected4:false,
             isSelected3:false,
             mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: "100%",
                      height: 50,

                    },
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        activated() {

          window.scrollTo(0, 0);

          this.$eventBus.$emit('reiniciarMenu1')
          this.$eventBus.$emit('reiniciarMenu2')

          this.idProducto=this.$route.params.id;
          this.url= window.location.protocol+"//"+window.location.hostname+"/m/" + this.$route.params.id+"/"+this.promoCode;
          this.loadDetails();

        },
        mounted() {

   
        
      
        },
        methods: {

          onCopyLink() {


            navigator.clipboard.writeText(this.url);
                    this.$toast.success('Link de venta copiado exitosamente!', {
                      position: 'top-right',
                      timeout: 1500,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })

          


        },
          shared(){

          if (navigator.share) {
                navigator.share({
                  title: 'EQCoop - ' + this.title,
                  text: this.title,
                  url: this.url,
                })
                  .then(() => console.log('Successful share'))
                  .catch((error) => console.log('Error sharing', error));
              }

          },

          changeImagen(imagen, type){

            switch (type) {
            case 'imagen1':

            this.isSelected1=true;
             this.isSelected2=false;
             this.isSelected4=false;
             this.isSelected3=false;
           
              break
              case 'imagen2':

              
            this.isSelected1=false;
             this.isSelected2=true;
             this.isSelected4=false;
             this.isSelected3=false;
           
           break
           case 'imagen3':

           
           this.isSelected1=false;
             this.isSelected2=false;
             this.isSelected4=false;
             this.isSelected3=true;
           
           break
           case 'imagen4':

           
           this.isSelected1=false;
             this.isSelected2=false;
             this.isSelected4=true;
             this.isSelected3=false;
           
           break

            }

          

  
            this.urlImagenShow=imagen;

          },
  
          loadDetails(){

            this.loading=true;


                this.$https.post('/locals/getDetailProduct/', {tokenAuth: this.tokenAuth, id: this.idProducto}).then(response => {


                if (response.data.code == 200) {

                  
                    this.loading = false;
                    this.empty=false;
                    this.isDelete=false;
                    this.isNotSuscription=false;
                   
                    this.chamaya= response.data.producto.chamaya;
                    this.urlImagen1= response.data.producto.urlImagen1;
                    this.urlImagen2= response.data.producto.urlImagen2;
                    this.urlImagen3= response.data.producto.urlImagen3;
                    this.urlImagen4= response.data.producto.urlImagen4;
                    this.urlImagenShow= response.data.producto.urlImagen1;
                    this.category=response.data.producto.category;
                    this.cobertura=response.data.producto.cobertura;

                    this.porcentajeUno=response.data.producto.porcentajeComisionPrimerNivel;
                    this.porcentajeDos=response.data.producto.porcentajeComisionSegundoNivel;
                    this.porcentajeTres=response.data.producto.porcentajeComisionTercerNivel;
                
                    this.title= response.data.producto.title;
                    this.recurrencia=response.data.producto.recurrencia;
                    this.alias= response.data.producto.alias;
                    this.price= response.data.producto.price;
                    this.comisionDirecta= Number(Number(this.price) * Number(response.data.producto.comisionDirecta)).toFixed(2)
                    this.rating= response.data.producto.rating;
                    this.description=response.data.producto.description;
                    this.idCategoria=response.data.producto.idCategoria;

                    this.showFoto=true;
                    this.showPorcentajes=true;
                    this.showCarousel=true;

                } else {


                if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    this.$router.push({ name: 'auth-login' })
                } else {

                    if(response.data.code == 404){

                      //no existe

                  

                   
                    this.loading= false;
                    this.empty=true;
                    this.isDelete=false;
                    this.isNotSuscription=false;
                  

                  
                    }else{

                      
                    if(response.data.code == 405){

                      //el usuario eliminó la publicación
                      this.loading= false;
                      this.empty=false;
                      this.isDelete=true;
                       this.isNotSuscription=false;

                    }else{
                      
                    if(response.data.code == 406){

                      //el usuario no tiene activado una suscripción

                      this.loading= false;
                      this.empty=false;
                      this.isDelete=false;
                       this.isNotSuscription=true;

                        }else{
                          this.loadDetails();
                        }
                    }


                     
                    

                    
                    }
                    
                }
                }
                }).catch(error => {
                this.loadDetails();
                })


          }
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      