<template>

  <b-row>
    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" >
    
        <p style="font-weight: bold;font-size:15px; text-align: left;margin-bottom:15px">Suscripciones destacadas</p>

    </b-col>

    <b-col v-if="loading" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" >

      <b-row>
        <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

          <b-skeleton width="100%"  height="150px"></b-skeleton>
            <b-skeleton width="30%"  height="10px"></b-skeleton>
            <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                              </b-col>

                              <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

                                <b-skeleton width="100%"  height="150px"></b-skeleton>
            <b-skeleton width="30%"  height="10px"></b-skeleton>
            <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                </b-col>

                                <b-col cols="4" sm="4" md="4" lg="4" xl="4" >

                                  <b-skeleton width="100%"  height="150px"></b-skeleton>
            <b-skeleton width="30%"  height="10px"></b-skeleton>
            <b-skeleton width="100%"  height="10px" style="margin-bottom: 10px;"></b-skeleton>



                                </b-col>

      </b-row>


      </b-col>
      <b-col v-else-if="empty" cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px" align="center" >

        <img src="/img/cart.svg" style="width: 70px;" />
        <p style="margin-top:15px; font-size:14px">No se encontraron publicaciones.</p>

        </b-col>

        <b-col v-else cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px"  >
          <carousel  :autoplay="false" :dots="false" :stagePadding="stagePadding" :nav="false" :items="3" :margin="margin" :responsive="{0:{items:1,nav:false},600:{items:2,nav:false},770:{items:1,nav:false},1200:{items:3,nav:false}}">

     
              <content-carousel :producto="producto" v-for="(producto) in productos" :key="producto.id"/>






              </carousel>

          </b-col>


 



   
  </b-row>
              

    
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BSkeleton
      } from 'bootstrap-vue'
      
      import  ContentCarousel from './ContentCarousel.vue'
      import carousel from 'vue-owl-carousel'
      export default {
        components: {
          carousel,
          ContentCarousel,
          BButton,
          BRow,
          BCol,
          BCard,
          BSkeleton
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['idCategoria'],
        data() {

          let stagePadding=0;
          let margin=10;
              if(this.$isMobile){
                stagePadding=10;
                margin=5;

              }
      
      
          return {
            productos: [],
            empty:false,
            loading:true,
            margin:margin,
            stagePadding:stagePadding,
            isMobile:this.$isMobile,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.loadProductos();
      
        },
        methods: {

          loadProductos(){
            this.$https.post('/locals/getProductosByCategoria/', { type: "Suscripcion", idCategoria:this.idCategoria}).then(response => {

                                    
                if (response.data.code == 200) {

                    this.loading = false;

                    this.productos= response.data.productos;
       
                } else {



                    if(response.data.code == 404){

                  

              
                    this.loading= false;
                    this.empty=true;
                    this.productos=[];
                 

                  
                    }else{


                        this.loadProductos();
                    

                    
                    }
                    
                
                }
                }).catch(error => {
                this.loadProductos();
                })
          }
  
  
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      