<template>

<b-card>

<b-row>





    <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:0px">
        <p style="font-weight: bold; text-align: center; margin-bottom:5px; font-size:14px;">Tu suscripción está protegida</p>

    <p style="text-align: left; margin:0px; font-size:12px;">Si hay algún problema o no recibes la suscripción tal y como lo compraste, te devolvemos el dinero que pagaste.</p>




      </b-col>

      

</b-row>

</b-card>
         
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard,BAvatar
      } from 'bootstrap-vue'
      
      
      
      export default {
        components: {
         
            BAvatar,
          BButton,
          BRow,
          BCol,
          BCard,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: [''],
        data() {
      
      
          return {
      
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
      
        },
        methods: {
  
          
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      