<template>

        <b-link     :to="{ name: 'marketplaceparam', params: { id: producto.id }}" >

                                            

                <b-img-lazy  v-bind="mainProps" :src="producto.urlImagen1" :alt="producto.title"></b-img-lazy>
                <b-row class="body_card">

                <b-col  cols="7" sm="12" md="12" lg="12" xl="12" style="padding-left: 0px;">
                    <p class="p_suscripciones"><span style="    font-size: 16px;">$</span>{{ producto.price }} <span style="    font-size: 10px;">USD</span></p>

                    </b-col>

                    <b-col  cols="5" sm="12" md="12" lg="12" xl="12" style="padding-right: 0px;display:flex">
                        <b-form-rating style="margin: auto;" variant="warning"  size="sm" no-border v-model="producto.rating" readonly></b-form-rating>

                    </b-col>



                <b-col  cols="12" sm="12" md="12" lg="12" xl="12" style="margin-top:10px" v-b-tooltip.hover.top="producto.title">
                    <p class="p_title_productos">{{ producto.title }}</p>
                
                    </b-col>

                </b-row>

                </b-link>   

        
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard,BLink, BImgLazy, BFormRating,VBTooltip
          } from 'bootstrap-vue'
          
          
          
          export default {
            components: {
             
        
              BButton,
              BLink,
              BFormRating,
              BImgLazy,
              BRow,
              BCol,
              BCard,
      
            },
            directives: {
              'b-popover': VBPopover,
              'b-tooltip': VBTooltip,
            },
            props: ['producto'],
            data() {
          
          
              return {
                mainProps: {
                      center: true,
                      fluidGrow: true,
                      blank: true,
                      blankColor: '#bbb',
                      width: 200,
                      height: 100,

                    },
          
              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
          
            },
            methods: {
      
      
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          