<template>

  

    <b-modal
            ref="modalGenerico"
            centered
            hide-footer
            size="sm"
            hideHeaderClose
            hideHeader
            @hidden="closeModalGenerico"
          >
            <div class="d-block text-center">

                <b-row v-if="!showConfirmacion"> 

                    <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style="margin-bottom:10px; padding:0px"
                        >

                        <b-alert  variant="secondary" style="margin-top: 5px !important; " show>
                            <div class="alert-body">

                                <p style="font-size: 12px;    margin: 0;text-align: left;font-weight: bold;">Descripción del pago:</p>

                                        <p style="font-size: 12px;    margin: 0;text-align: left;text-align: left;display: -webkit-box;vertical-align: middle;text-overflow: ellipsis;overflow: hidden;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">{{ title }}</p>

                            
                            
                            </div>
                            </b-alert>

                      
                         

                    
                        </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">

                        <b-form   autocomplete="off" @submit.prevent="onSubmit">
                        <b-row>

                            <b-col
                             cols="5"
                                sm="5"
                                md="5"
                                lg="5"
                                xl="5"
                            >

                                <div class="profile-image p-0">
                                    <b-avatar
                                    size="50"
                                    variant="light"
                                    :src="photoUser"
                                    />
                                </div>


                            <h4 :title="displayName"  class="font-weight-bolder userTransfers">
                                {{ displayName }}
                                </h4>



                            </b-col>

                                <b-col
                                cols="2"
                                sm="2"
                                md="2"
                                lg="2"
                                xl="2"
                                style="display: flex;"
                            >

                            <svg width="30" height="30"  class="flecha" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9.0L441 239c9.4 9.4 9.4 24.6.0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9.0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3.0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>

                            </b-col>

                            <b-col
                                cols="5"
                                sm="5"
                                md="5"
                                lg="5"
                                xl="5"
                            >

                                <div class="profile-image p-0">
                                    <b-avatar
                                    size="50"
                                    variant="light"
                                    :src="foto"
                                    />
                                </div>


                            <h4 :title="alias" class="font-weight-bolder userTransfers">
                                @{{ alias }}
                                </h4>



                            </b-col>



                

                                        <b-col  cols="12" sm="12"  md="12" lg="12"  xl="12" style="margin-top:10px; padding:0px">
                                            <b-card>

                                                <p style="font-size:13px;  text-align: left; margin-bottom: 10px;font-weight: bold;">Seleccione el estado actual de su compra:</p>

                                                <b-form-group style="margin: 0px;">
                                                <b-form-radio-group
                                                required

                                                    v-model="hasFinishVenta"
                                                    :options="options"
                                                    stacked
                                                />
                                                </b-form-group>

                                            </b-card>

                                          

                                            </b-col>

                              

                                        <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="margin-bottom: 10px;">
                                        <p
                                                    class="textLabel"
                                                    style="text-align: center;margin-bottom: 10px;"
                                                >
                                                    Ingrese su código PIN (*): <feather-icon
                                                                    icon="HelpCircleIcon"
                                                                    size="16"
                                                                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                    title="Código PIN de seguridad"
                                                                    style="margin-right: 5px;cursor:pointer;"
                                                                    /> </p>

                                                <PincodeInput
                                                    v-model="pin"
                                                    placeholder="-"
                                                    :length="6"
                                                    :autofocus="false"
                                                    :secure="true"
                                                    required
                                                    style="margin:0px"
                                                />

                                            </b-col>

                            <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="text-align: right;">


                                            <b-button
                                                    id="btn_pay"
                                                    ref="btn_pay"
                                                    type="submit"
                                                    variant="primary"
                                                    
                                                    :disabled="isActive"
                                                >
                                    
                                                    Pagar ${{ price }} USD
                                    
                                                </b-button>
                            </b-col>

                        </b-row>
                  </b-form>

                    </b-col>
                </b-row>

                <b-row v-else>

                    <b-col
                  cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    style="margin-bottom: 10px;"
                  >

                  <img  src="/img/check.svg" alt="Pago exitoso" />

                  </b-col>

                    <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" >

                    <p style="font-weight: bold;text-align: center;margin-bottom:15px; font-size:16px">Pago realizado con éxito</p>

                    </b-col>

                    <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" >

                       
                            <p style="text-align: justify; font-size:13px; margin-bottom:15px">Su compra de esta suscripción se encuentra en <b>estado de negociación</b>. Los próximos pasos a seguir son los siguientes:</p>
                        <b-row>
                            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                                    <b-avatar
                                            size="25"
                                            variant="light-primary"
                                            style="margin:auto;background: #00838c !important;color: white !important;">

                                            <span class="d-flex align-items-center">
                                            1

                                            </span>

                                            </b-avatar>


                                    </b-col>
                                    <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
                                    
                                    <p style="text-align:left;margin:0px; font-size:13px">En las próximas horas el negocio se pondrá en contacto con usted para finalizar detalles sobre la suscripción que acabó de comprar.</p>

                                    </b-col>

                                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <hr style="margin:10px">
                                    </b-col>


                                    <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                                        <b-avatar
                                            size="25"
                                            variant="light-primary"
                                            style="margin:auto;background: #00838c !important;color: white !important;">

                                            <span class="d-flex align-items-center">
                                            2

                                            </span>

                                            </b-avatar>


                                        </b-col>
                                        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                                    <p style="text-align: left;margin:0px; font-size:13px">Si el negocio no se pone en contacto con usted en las próximas 72 horas puede optar por cancelar esta compra y el dinero se le será devuelto.</p>


                                    </b-col>

                                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <hr style="margin:10px">
                                    </b-col>

                                    <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                                        <b-avatar
                                            size="25"
                                            variant="light-primary"
                                            style="margin:auto;background: #00838c !important;color: white !important;">

                                            <span class="d-flex align-items-center">
                                            3

                                            </span>

                                            </b-avatar>


                                    </b-col>
                                    <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                                    <p style="text-align: left;margin:0px; font-size:13px">Cuando llegue a un acuerdo con el negocio, deberá cambiar el estado de la transacción de <b>"negociación"</b> a <b>"finalizado"</b> para que el comercio reciba el dinero por la suscripción que compró.</p>



                                    </b-col>
                        </b-row>
                        
                        
                  


                        </b-col>
                    

                    <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="text-align: right;">

                        <hr>
                        <b-button
                                variant="primary"
                                @click="close"
                            >

                                Entendido

                            </b-button>
                        </b-col>

                </b-row>



             
            </div>
      
          </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow, VBPopover, BCol, BCard, BForm, BAvatar, BSkeleton, BAlert, BFormCheckbox, BFormRadioGroup, BFormGroup
          } from 'bootstrap-vue'
          
          import PincodeInput from 'vue-pincode-input'
          
          export default {
            components: {
                PincodeInput,
                BFormRadioGroup, BFormGroup,
                BFormCheckbox,
                BSkeleton,
                BForm,
                BAlert,
                BButton,
                BRow,
                BCol,
                BCard,
                BAvatar
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: [ 'tokenAuth','promoCode', 'userId','displayName','photoUser', 'recurrencia', 'price', 'title', 'foto', 'alias', 'idProducto'],
            data() {

               


                let periodo="";
                let texto1="";

                
            switch (this.recurrencia) {

                    case "Diario":
       
                    periodo="diariamente"
            
                      break
                    case "Mensual":
    
                    periodo="mensualmente"
                      break

                      case "Trimestral":
       
                      periodo="trimestralmente"
           
                      break;

                      case "Semestral":

                      periodo="semestralmente"
                      break;


                      case "Anual":

                      periodo="anualmente"
                    break;


                  }

                  if(this.recurrencia === "Pago unico"){
                    texto1= "He contactado con el negocio y llegamos a un acuerdo, por tal motivo, apruebo en realizar el pago correspondiente por esta suscripción";

                  }else{
                    texto1= 'He contactado con el negocio y llegamos a un acuerdo, por tal motivo, apruebo a realizar el pago y que se me debite '+periodo+ " de mi saldo EQCoop la cantidad a pagar por esta suscripción";

                  }

              
          
              return {
                isActive:false,
                pin:"",
                loading:true,
                showConfirmacion:false,
                periodo:periodo,
                hasFinishVenta:null,
                options: [
                        { text: texto1, value: true },
                        { text: 'Necesito contactarme con el negocio, por tal motivo, apruebo que mi compra quedé en estado de negociación y que se debité de mi saldo EQCoop hasta llegar a un acuerdo con el negocio para posteriormente aprobar o cancelar esta compra.', value: false },
                     
                    ],

              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
       
            
            this.$refs.modalGenerico.show();

        
    

            },
            methods: {

     

                close(){
                    this.$refs.modalGenerico.hide();
                },

               
             closeModalGenerico(){
                    this.$eventBus.$emit('reiniciarModalPayGenerico')
                
                },

              
                    onSubmit(event) {
                    event.preventDefault();
                
            
                            this.isActive = true;

                                    const userId_json = {
                                        userId: this.userId, promoCode: this.promoCode, pin: this.pin, idProducto:this.idProducto, hasFinishVenta:this.hasFinishVenta
                                    }
                                    const user_string = JSON.stringify(userId_json)


                                    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
                                    document.getElementById('btn_pay').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando pago';
    

                                    this.$https.post('/subcripcion/paySubcriptionFinal/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                                        document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.price+' USD';
                                        this.isActive = false;
                                    if (response.data.code == 200) {

                                        this.pin="";
                                        this.isActive=false;
              

                                        this.$toast.success(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        });

                                        this.$eventBus.$emit('reiniciarListadoCompras')
                                        this.$eventBus.$emit('reiniciarCountCompras')

                                        this.$eventBus.$emit('reiniciarListadoVentas')
                                        this.$eventBus.$emit('reiniciarCountVentas')

                                        if(!this.hasFinishVenta){
                                            this.showConfirmacion=true;

                                            this.$eventBus.$emit('reiniciarCountSuscripcionesActivas')

                                        }else{

                                            this.close();

                                        }

                                      

                                    } else {
                                        this.isActive = false;
                                        this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })

                                    if (response.data.code == 401) {

                                     

                                        localStorage.removeItem('userData')



                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })

                                    }else{
                                        if(response.data.code === 503){

                                            this.pin="";

                                        }else{

                                            if(response.data.code === 501){
                                                this.$refs.modalGenerico.hide();

                                                this.$swal({
                                                        title: 'Tu perfil debe estar verificado para realizar esta acción',
                                                        text: `¿Deseas verificar tu cuenta ahora?`,
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Verificar cuenta',
                                                        cancelButtonText: 'No',
                                                        customClass: {
                                                            confirmButton: 'btn btn-primary',
                                                            cancelButton: 'btn btn-outline-secondary ml-1',
                                                        },
                                                        buttonsStyling: false,
                                                        }).then(result => {
                                                        if (result.value) {

                                                            this.$router.push({ name: 'perfil', hash: '#verificacion' })

                                                        }
                                                        })

                                            }
                                            
                
                                        }

                                    }


                                    }
                                    }).catch(error => {
                                        this.isActive = false;
                                        document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.price+' USD';
    
                                        this.$toast.error("Ocurrió un error inesperado " + error, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })
                                    })

                    
                
                    },
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          