<template>

  

    <b-modal
            ref="modalSeguidores"
            centered
            hide-footer
            size="sm"
            hideHeaderClose
            hideHeader
            @hidden="closeModalSeguidores"
          >
            <div class="d-block text-center">

                <b-row> 

                   

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

                        <b-form   autocomplete="off" @submit.prevent="onSubmit">
                        <b-row>

                            


                                               

                                                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom: 10px;">
                                                        
                                                        <p class="textLabel" style="text-align: left;margin-bottom: 5px;margin-top:0px;" > {{ txtLabel }} (*): </p>

                                                        <b-form-input
                                                            v-model="link"
                                                            :placeholder="txtLabel"
                                                            required
                                                            type="text"
                                                            :readonly="isActive2"
                                                            />

                                                    </b-col>



                                        <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="margin-bottom: 10px;">
                                        <p
                                                    class="textLabel"
                                                    style="text-align: center;margin-bottom: 10px;"
                                                >
                                                    Ingrese su código PIN (*): <feather-icon
                                                                    icon="HelpCircleIcon"
                                                                    size="16"
                                                                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                    title="Código PIN de seguridad"
                                                                    style="margin-right: 5px;cursor:pointer;"
                                                                    /> </p>

                                                <PincodeInput
                                                    v-model="pin"
                                                    placeholder="-"
                                                    :length="6"
                                                    :autofocus="false"
                                                    :secure="true"
                                                    required
                                                    style="margin:0px"
                                                />

                                            </b-col>

                            <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="text-align: right;">


                                            <b-button
                                                    id="btn_pay"
                                                    ref="btn_pay"
                                                    type="submit"
                                                    variant="primary"
                                                    
                                                    :disabled="isActive"
                                                >
                                    
                                                    Pagar ${{ price }} USD
                                    
                                                </b-button>
                            </b-col>

                        </b-row>
                  </b-form>

                    </b-col>
                </b-row>

           
             
            </div>
      
          </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow,BFormSelect, VBPopover, BCol, BCard, BForm, BAvatar, BSkeleton,BFormInput, BAlert, BFormCheckbox, BFormRadioGroup, BFormGroup
          } from 'bootstrap-vue'
          
          import PincodeInput from 'vue-pincode-input'
          
          export default {
            components: {
                PincodeInput,
                BFormSelect,
                BFormInput,
                BFormRadioGroup, BFormGroup,
                BFormCheckbox,
                BSkeleton,
                BForm,
                BAlert,
                BButton,
                BRow,
                BCol,
                BCard,
                BAvatar
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: [ 'tokenAuth','promoCode','chamaya', 'userId','displayName','photoUser', 'recurrencia', 'price', 'title', 'foto', 'alias', 'idProducto'],
            data() {

                
                let periodo="";
                let texto1="";

         
                if(this.chamaya === "Seguidores"){

                    texto1="Ingrese el link del perfil que desea incrementar en seguidores";
                   

                }else{
                    if(this.chamaya === "Likes"){
                        texto1="Ingrese el link de la publicación que desea incrementar sus likes";
       
                    }else{
                        texto1="Ingrese el link del perfil o publicación";
                    }
                }

                
      

          
              return {
                isActive:false,
                isActive2:false,
                pin:"",
                txtLabel:texto1,
                loading:true,
                periodo:periodo,
                    link:"",


              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
       
            
            this.$refs.modalSeguidores.show();

          
        
    

            },
            methods: {

         

  

                close(){
                    this.$refs.modalSeguidores.hide();
                },

               
             closeModalSeguidores(){
                    this.$eventBus.$emit('reiniciarModalSeguidores')
                
                },

              
                    onSubmit(event) {
                    event.preventDefault();
                
                  

                            this.isActive = true;
                            this.isActive2=true;

                                    const userId_json = {
                                        userId: this.userId,promoCode: this.promoCode,  pin: this.pin, idProducto:this.idProducto, link: this.link, chamaya:this.chamaya
                                    }
                                 
                                    document.getElementById('btn_pay').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando pago';
    

                                    this.$https.post('/subcripcion/paySeguidores/', { tokenAuth: this.tokenAuth, data: userId_json }).then(response => {

                                        document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.price+' USD';
                                        this.isActive = false;
                                        this.isActive2=false;
                                    if (response.data.code == 200) {

                                        this.pin="";
                                        this.isActive=false;
                                        this.isActive2=false;

                                        this.$toast.success(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        });

                                        this.close();

                                    } else {
                                        this.isActive = false;
                                        this.isActive2=false;
                                        this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })

                                    if (response.data.code == 401) {

                                     

                                        localStorage.removeItem('userData')



                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })

                                    }else{
                                        if(response.data.code === 503){

                                            this.pin="";

                                        }else{

                                            if(response.data.code === 501){
                                                this.$refs.modalSeguidores.hide();

                                                this.$swal({
                                                        title: 'Tu perfil debe estar verificado para realizar esta acción',
                                                        text: `¿Deseas verificar tu cuenta ahora?`,
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Verificar cuenta',
                                                        cancelButtonText: 'No',
                                                        customClass: {
                                                            confirmButton: 'btn btn-primary',
                                                            cancelButton: 'btn btn-outline-secondary ml-1',
                                                        },
                                                        buttonsStyling: false,
                                                        }).then(result => {
                                                        if (result.value) {

                                                            this.$router.push({ name: 'perfil', hash: '#verificacion' })

                                                        }
                                                        })

                                            }
                                            
                
                                        }

                                    }


                                    }
                                    }).catch(error => {
                                        this.isActive = false;
                                        this.isActive2=false;
                                        document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.price+' USD';
    
                                        this.$toast.error("Ocurrió un error inesperado " + error, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })
                                    })

                      
                
                    },
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          