<template>

  

    <b-modal
            ref="modalPets"
            centered
            hide-footer
            size="sm"
            hideHeaderClose
            hideHeader
            @hidden="closeModalPets"
          >
            <div class="d-block text-center">

                <b-row v-if="!showConfirmacion"> 

                   

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

                        <b-form   autocomplete="off" @submit.prevent="onSubmit">
                        <b-row>

                            

                        

                                        <b-col  cols="12" sm="12"  md="12" lg="12"  xl="12">
                                                        <p class="textLabel" style="text-align: left;margin-bottom: 5px;margin-top:0px;" > Seleccione el tipo de mascota (*): </p>

                                                        </b-col>

                                                  

                                                    <b-col
                                                            cols="6"
                                                                sm="6"
                                                                md="6"
                                                                lg="6"
                                                                xl="6"
                                                            >

                                                            <div class="inputGroup">



                                                            <input id="Perro" name="radio" @click="onChange('Perro')" :checked="true"  value="Perro" type="radio"/>
                                                            <label for="Perro"> 

                                                            <span> <img  alt="Perro" style="margin-right: 15px;width: 25px;" src="/img/dog.svg">  </span>Perro</label>
                                                            </div>

                                                            </b-col>

                                                            <b-col
                                                            cols="6"
                                                                sm="6"
                                                                md="6"
                                                                lg="6"
                                                                xl="6"
                                                                style="margin-bottom:5px"
                                                            >

                                                            <div class="inputGroup">



                                                            <input id="Gato" name="radio" @click="onChange('Gato')"  value="Gato" type="radio"/>
                                                            <label for="Gato"> 

                                                            <span> <img  alt="Gato" style="margin-right: 15px;width: 25px;" src="/img/cat.svg">  </span>Gato</label>
                                                            </div>

                                                            </b-col>


                                                <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="margin-bottom:10px">
                                                        
                                                        <p class="textLabel" style="text-align: left;margin-bottom: 5px;margin-top:0px;" > Ingrese el nombre de la mascota(*): </p>

                                                        <b-form-input
                                                            v-model="nameMascota"
                                                            placeholder="Ingrese el nombre de su mascota"
                                                            required
                                                            @keypress="isLetter"
                                                            type="text"
                                                            :readonly="isActive2"
                                                            />

                                                    </b-col>




                                                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                        
                                                        <p class="textLabel" style="text-align: left;margin-bottom: 5px;margin-top:0px;" > Ingrese la edad de la mascota(*): </p>

                                                 

                                                    </b-col>

                                                    <b-col cols="4" sm="4" md="4" lg="4" xl="4">
                                                        
                                                       
                                                        <b-form-input
                                                            v-model="age"
                                                            placeholder="Edad"
                                                            required
                                                            @keypress="NumbersOnly"
                                                            type="tel"
                                                            :readonly="isActive2"
                                                            />


                                                    </b-col>

                                                    <b-col cols="8" sm="8" md="8" lg="8" xl="8">
                                                        
                                                       
                                                        <b-form-select
                                                        required
                                                            v-model="meses"
                                                            :options="optionsTiempos"
                                                            block
                                                            :disabled="isActive2"
                                                            size="md"
                                                            />
                        


                                                    </b-col>




                                        <b-col  cols="12" sm="12"  md="12" lg="12"  xl="12" style=" margin-top:10px">
                                            <b-card>

                                                <p style="font-size:13px;  text-align: left; margin-bottom: 10px;font-weight: bold;">Seleccione el estado actual de su compra:</p>

                                                <b-form-group style="margin: 0px;">
                                                <b-form-radio-group
                                                required
                                                    v-model="hasFinishVenta"
                                                    :options="options"
                                                    stacked
                                                />
                                                </b-form-group>

                                            </b-card>

                                          

                                            </b-col>

                              

                                        <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="margin-bottom: 10px;">
                                        <p
                                                    class="textLabel"
                                                    style="text-align: center;margin-bottom: 10px;"
                                                >
                                                    Ingrese su código PIN (*): <feather-icon
                                                                    icon="HelpCircleIcon"
                                                                    size="16"
                                                                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                                                    title="Código PIN de seguridad"
                                                                    style="margin-right: 5px;cursor:pointer;"
                                                                    /> </p>

                                                <PincodeInput
                                                    v-model="pin"
                                                    placeholder="-"
                                                    :length="6"
                                                    :autofocus="false"
                                                    :secure="true"
                                                    required
                                                    style="margin:0px"
                                                />

                                            </b-col>

                            <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="text-align: right;">


                                            <b-button
                                                    id="btn_pay"
                                                    ref="btn_pay"
                                                    type="submit"
                                                    variant="primary"
                                                    
                                                    :disabled="isActive"
                                                >
                                    
                                                    Pagar ${{ price }} USD
                                    
                                                </b-button>
                            </b-col>

                        </b-row>
                  </b-form>

                    </b-col>
                </b-row>

                <b-row v-else>

                    <b-col
                  cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    align="center"
                    style="margin-bottom: 10px;"
                  >

                  <img  src="/img/check.svg" alt="Pago exitoso" />

                  </b-col>

                    <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" >

                    <p style="font-weight: bold;text-align: center;margin-bottom:15px; font-size:16px">Pago realizado con éxito</p>

                    </b-col>

                    <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" >

                       
                            <p style="text-align: justify; font-size:13px; margin-bottom:15px">Su compra de esta suscripción se encuentra en <b>estado de negociación</b>. Los próximos pasos a seguir son los siguientes:</p>
                        <b-row>
                            <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">

                                    <b-avatar
                                            size="25"
                                            variant="light-primary"
                                            style="margin:auto;background: #00838c !important;color: white !important;">

                                            <span class="d-flex align-items-center">
                                            1

                                            </span>

                                            </b-avatar>


                                    </b-col>
                                    <b-col cols="10" sm="10" md="10" lg="10" xl="10" >
                                    
                                    <p style="text-align:left;margin:0px; font-size:13px">En las próximas horas el negocio se pondrá en contacto con usted para finalizar detalles sobre la suscripción que acabó de comprar.</p>

                                    </b-col>

                                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <hr style="margin:10px">
                                    </b-col>


                                    <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                                        <b-avatar
                                            size="25"
                                            variant="light-primary"
                                            style="margin:auto;background: #00838c !important;color: white !important;">

                                            <span class="d-flex align-items-center">
                                            2

                                            </span>

                                            </b-avatar>


                                        </b-col>
                                        <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                                    <p style="text-align: left;margin:0px; font-size:13px">Si el negocio no se pone en contacto con usted en las próximas 72 horas puede optar por cancelar esta compra y el dinero se le será devuelto.</p>


                                    </b-col>

                                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <hr style="margin:10px">
                                    </b-col>

                                    <b-col cols="2" sm="2" md="2" lg="2" xl="2" style="display:flex">
                                        <b-avatar
                                            size="25"
                                            variant="light-primary"
                                            style="margin:auto;background: #00838c !important;color: white !important;">

                                            <span class="d-flex align-items-center">
                                            3

                                            </span>

                                            </b-avatar>


                                    </b-col>
                                    <b-col cols="10" sm="10" md="10" lg="10" xl="10" >

                                    <p style="text-align: left;margin:0px; font-size:13px">Cuando llegue a un acuerdo con el negocio, deberá cambiar el estado de la transacción de <b>"negociación"</b> a <b>"finalizado"</b> para que el comercio reciba el dinero por la suscripción que compró.</p>



                                    </b-col>
                        </b-row>
                        
                        
                  


                        </b-col>
                    

                    <b-col cols="12"  sm="12"   md="12" lg="12" xl="12" style="text-align: right;">

                        <hr>
                        <b-button
                                variant="primary"
                                @click="close"
                            >

                                Entendido

                            </b-button>
                        </b-col>

                </b-row>



             
            </div>
      
          </b-modal>
          
          </template>
          
          <script>
          import {
            BButton, BRow,BFormSelect, VBPopover, BCol, BCard, BForm, BAvatar, BSkeleton,BFormInput, BAlert, BFormCheckbox, BFormRadioGroup, BFormGroup
          } from 'bootstrap-vue'
          
          import PincodeInput from 'vue-pincode-input'
          
          export default {
            components: {
                PincodeInput,
                BFormSelect,
                BFormInput,
                BFormRadioGroup, BFormGroup,
                BFormCheckbox,
                BSkeleton,
                BForm,
                BAlert,
                BButton,
                BRow,
                BCol,
                BCard,
                BAvatar
            },
            directives: {
              'b-popover': VBPopover,
            },
            props: [ 'tokenAuth','promoCode', 'userId','displayName','photoUser', 'recurrencia', 'price', 'title', 'foto', 'alias', 'idProducto'],
            data() {

                
                let periodo="";
                let texto1="";

                
            switch (this.recurrencia) {

                    case "Diario":
       
                    periodo="diariamente"
            
                      break
                    case "Mensual":
    
                    periodo="mensualmente"
                      break

                      case "Trimestral":
       
                      periodo="trimestralmente"
           
                      break;

                      case "Semestral":

                      periodo="semestralmente"
                      break;


                      case "Anual":

                      periodo="anualmente"
                    break;


                  }

                  if(this.recurrencia === "Pago unico"){
                    texto1= "He contactado con el negocio y llegamos a un acuerdo, por tal motivo, apruebo en realizar el pago correspondiente por esta suscripción";

                  }else{
                    texto1= 'He contactado con el negocio y llegamos a un acuerdo, por tal motivo, apruebo a realizar el pago y que se me debite '+periodo+ " de mi saldo EQCoop la cantidad a pagar por esta suscripción";

                  }

          
              return {
                isActive:false,
                isActive2:false,
                pin:"",
                loading:true,
                showConfirmacion:false,
                periodo:periodo,
                age:"",
                meses:"",
                hasFinishVenta:null,
                options: [
                          { text: texto1, value: true },
                        { text: 'Necesito contactarme con el negocio, por tal motivo, apruebo que mi compra quedé en estado de negociación y que se debité de mi saldo EQCoop hasta llegar a un acuerdo con el negocio para posteriormente aprobar o cancelar esta compra.', value: false },
                     
                     
                    ],
                    typeMascota:"Perro",
                    nameMascota:"",
                optionsTiempos: [
                { value: '', text: 'Seleccione una opción' },
                { value: 'Meses', text: 'Meses' },
                { value: 'Años', text: 'Años' },
              
                ],

              }
            },
            computed: {
          
            },
            watch: {
          
            },
            mounted() {
       
            
            this.$refs.modalPets.show();

          
        
    

            },
            methods: {

                NumbersOnly(evt) {
                  evt = (evt) || window.event
                  const charCode = (evt.which) ? evt.which : evt.keyCode
                  if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                    evt.preventDefault()
                  } else {
                    return true
                  }
                },

                          isLetter(e) {
                    const char = String.fromCharCode(e.keyCode) // Get the character
                    if (/^[a-zA-Z\s]*$/g.test(char)) return true // Match with regex
                    e.preventDefault() // If not match, don't add to input text
                  },

                onChange(event){
                    this.typeMascota= event;
                },

  

                close(){
                    this.$refs.modalPets.hide();
                },

               
             closeModalPets(){
                    this.$eventBus.$emit('reiniciarModalPayPets')
                
                },

              
                    onSubmit(event) {
                    event.preventDefault();
                
                  

                            this.isActive = true;
                            this.isActive2=true;

                                    const userId_json = {
                                        userId: this.userId,promoCode: this.promoCode,  pin: this.pin, idProducto:this.idProducto, hasFinishVenta:this.hasFinishVenta, typeMascota:this.typeMascota, nameMascota: this.nameMascota, age: this.age, meses:this.meses
                                    }
                                    const user_string = JSON.stringify(userId_json)


                                    const dataEncripted = this.$encryptBackoffice.encrypt(user_string)
                                    document.getElementById('btn_pay').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando pago';
    

                                    this.$https.post('/subcripcion/paySubcriptionFinal/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {

                                        document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.price+' USD';
                                        this.isActive = false;
                                        this.isActive2=false;
                                    if (response.data.code == 200) {

                                        this.pin="";
                                        this.isActive=false;
                                        this.isActive2=false;

                                        this.$toast.success(response.data.message, {
                                            position: 'top-right',
                                            timeout: 3010,
                                            closeOnClick: true,
                                            pauseOnFocusLoss: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            draggablePercent: 0.4,
                                            showCloseButtonOnHover: false,
                                            hideProgressBar: true,
                                            closeButton: 'button',
                                            icon: true,
                                            rtl: false,
                                        });

                                        if(!this.hasFinishVenta){
                                            this.showConfirmacion=true;
                                            this.$eventBus.$emit('reiniciarCountSuscripcionesActivas')

                                        }else{

                                            this.close();

                                        }

                                    } else {
                                        this.isActive = false;
                                        this.isActive2=false;
                                        this.$toast.error(response.data.message, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })

                                    if (response.data.code == 401) {

                                     

                                        localStorage.removeItem('userData')



                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })

                                    }else{
                                        if(response.data.code === 503){

                                            this.pin="";

                                        }else{

                                            if(response.data.code === 501){
                                                this.$refs.modalPets.hide();

                                                this.$swal({
                                                        title: 'Tu perfil debe estar verificado para realizar esta acción',
                                                        text: `¿Deseas verificar tu cuenta ahora?`,
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonText: 'Verificar cuenta',
                                                        cancelButtonText: 'No',
                                                        customClass: {
                                                            confirmButton: 'btn btn-primary',
                                                            cancelButton: 'btn btn-outline-secondary ml-1',
                                                        },
                                                        buttonsStyling: false,
                                                        }).then(result => {
                                                        if (result.value) {

                                                            this.$router.push({ name: 'perfil', hash: '#verificacion' })

                                                        }
                                                        })

                                            }
                                            
                
                                        }

                                    }


                                    }
                                    }).catch(error => {
                                        this.isActive = false;
                                        this.isActive2=false;
                                        document.getElementById('btn_pay').innerHTML = 'Pagar $'+this.price+' USD';
    
                                        this.$toast.error("Ocurrió un error inesperado " + error, {
                                        position: 'top-right',
                                        timeout: 3010,
                                        closeOnClick: true,
                                        pauseOnFocusLoss: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        draggablePercent: 0.4,
                                        showCloseButtonOnHover: false,
                                        hideProgressBar: true,
                                        closeButton: 'button',
                                        icon: true,
                                        rtl: false,
                                    })
                                    })

                      
                
                    },
             
          
            },
          }
          </script>
          
          <style lang="scss">
          
          
          
          </style>
          